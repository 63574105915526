.licence-visibility {
  display: none;
}

.licence-preview-card {
  max-width: 442px;
  position: relative;
  font-size: 11pt;
  z-index: 2;
  // left:25px;
  border: 1px solid $secondaryColor;
  box-sizing: border-box;

  // display: flex;
  width: 460px;

  .counter_name {
    position: absolute;
    width: 100%;
    font-size: 11px;
    top: 7%;
    font-weight: bold;
    left: 68%;
  }

  .licence_number {
    font-weight: bold;
    padding-left: 32px;
  }

  img {
    width: 440px;
    height: 520px;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    z-index: 1;
    left: 2%;
  }

  .next {
    left: 94%;
  }

  .helperText {
    margin-left: 5%;
  }

  .mainContent {
    position: absolute;
    width: 100%;
    padding-right: 8px;
    top: 11%;
    left: 1%;
  }

  .qrImage {
    img {
      width: 100px;
      height: 100px;
    }
  }

  .emptySpace {
    margin-top: 17%;
  }

  .main-container {
    width: 100%;
    padding-left: 4px;
    margin-top: 12px;
  }

  .fontStyle {
    font-style: helvetica;
    font-weight: bold !important;
    letter-spacing: 2pt;
  }

  .licence-date {
    font-style: Verdana;
    font-size: 22pt;
    font-weight: bold !important;
    letter-spacing: 2pt;
    padding-right: 23px;
  }

  .owner-name-address-label {
    font-style: Verdana;
    font-size: 11pt;
    padding-left: 18px;
    font-weight: bold !important;
  }

  .owner-name {
    font-style: Verdana;
    font-size: 11pt;
    padding-left: 18px;
  }

  .owner-name-2 {
    font-style: Verdana;
    font-size: 11pt;
    padding-left: 18px;
  }

  .owner-address {
    font-style: Verdana;
    font-size: 11pt;
    padding-top: 18px;
    padding-left: 18px;
  }

  .amount-container {
    margin-left: -11px;

    .licence-total-amount-label {
      font-style: Verdana;
      word-break: none;
      text-align: right;
      font-size: 11pt;
      font-weight: bold !important;
      padding-right: 0px;
    }

    .licence-total-amount {
      font-style: Verdana;
      font-size: 11pt;
      text-align: right;
      padding-right: 0px;
      font-weight: bold;
    }

    .licence-amounts-label {
      font-style: Verdana;
      font-size: 11pt;
      padding: 0;
    }

    .licence-amounts {
      font-style: Verdana;

      padding: 0;
    }
  }

  .bottom-label-container {
    margin-top: 16px;
    padding-left: 8px;

    .validity-period-label {
      font-weight: bold !important;
    }

    .validity-period-value {
      font-weight: bold !important;
    }

    .vet-no-label {
      font-weight: bold !important;
    }

    .vet-no-value {
      inline-size: 150px;
      overflow-wrap: break-word;
    }
  }



  .bottom-label-container2 {
    margin-top: 2px;
    margin-left: 0px;
    width: 100%;

    .unladen-weight-label {
      font-weight: bold !important;
    }

    .unladen-weight-value {
      margin-left: 8px;
    }


    .no-of-seats-label {
      font-weight: bold !important;
    }

    .no-of-seats-value {
      padding-left: 8px;
    }
  }

  .not_for_running_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .not_for_running {
    font-weight: bold;
    width: 200px;
    text-align: center;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
  }

  .footer-content {
    width: 100%;
    margin-left: 8px;

    .signature-place {
      margin-top: 30px;
    }

    .signature-place_web {
      min-height: 30px;
      margin-top: 30px;
    }

    .signature-details {}
  }
}


.web-print-card {
  min-width: 1000px;
  height: 950px;
  margin-top: 5px;
  margin-right: 0px;
  font-family: 'Roboto Mono', monospace;

  span {
    position: absolute;
  }


  .Text5 {
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
  }


  .F1 {
    font-size: 9.5pt;
    font-weight: bold;
    left: 501px;
    top: 40.5px;
    right: 215px;
    bottom: 98.8px;
    font-family: 'Roboto Mono', monospace;

  }

  .F2 {
    left: 41.7px;
    top: 151.5px;
    right: 535.8px;
    bottom: 154.3px;
    font-size: 15pt;
    font-weight: bold;
    font-family: 'Roboto Mono', monospace;
  }

  .F3 {
    left: 41.7px;
    top: 170.5px;
    right: 536px;
    bottom: 99px;
    font-weight: bold;
    font-size: 16pt;
    font-family: 'Roboto Mono', monospace;

  }

  .F4 {
    left: 41.7px;
    top: 198.5px;
    right: 536px;
    bottom: 99px;
    font-size: 15pt;
    font-weight: bold;
    font-family: 'Roboto Mono', monospace;
  }

  .F5 {
    left: 269px;
    top: 82px;
    right: 53.6px;
    bottom: 133px;
    font-size: 37pt;
    font-weight: bold;
    font-family: 'Roboto Mono', monospace;

  }

  .F6 {
    left: 41.7px;
    top: 250.5px;
    right: 320px;
    bottom: 99px;
    font-size: 13pt;
    font-family: 'Roboto Mono', monospace;

  }

  .F7 {
    left: 96.5px;
    top: 246.5px;
    right: 536px;
    bottom: 99px;
    font-size: 13pt;
    font-family: 'Roboto Mono', monospace;

  }

  .F8 {
    left: 176.5px;
    top: 246.5px;
    right: 20px;
    bottom: 99px;
    font-size: 13pt;
    font-family: 'Roboto Mono', monospace;

  }

  .F9 {
    left: 41.7px;
    top: 289.5px;
    right: 400px;
    bottom: 99px;
    font-size: 13pt;
    font-family: 'Roboto Mono', monospace;
  }

  .F10 {
    left: 26.5px;
    top: 289.5px;
    right: 300px;
    bottom: 99px;
    font-size: 13pt;
    font-family: 'Roboto Mono', monospace;
  }

  .F11 {
    left: 165.5px;
    top: 289.5px;
    right: 20px;
    bottom: 99px;
    font-size: 13pt;
    font-family: 'Roboto Mono', monospace;
  }

  .F12 {
    left: 41.7px;
    top: 390.5px;
    right: 99px;
    bottom: 99px;
    font-size: 13pt;
    font-weight: bold;
    font-family: 'Roboto Mono', monospace;
  }

  .F13 {
    left: 160px;
    top: 390.5px;
    right: 500px;
    bottom: 99px;
    font-size: 13pt;
    font-family: 'Roboto Mono', monospace;

  }

  .F14 {
    left: 176.5px;
    top: 390.5px;
    right: 250px;
    bottom: 99px;
    font-size: 13pt;
    font-family: 'Roboto Mono', monospace;
  }

  .F15 {
    left: 144.5px;
    top: 320.5px;
    right: 536px;
    bottom: 99px;
    font-size: 13pt;
    font-family: 'Roboto Mono', monospace;
  }

  .F16 {
    left: 41.7px;
    top: 451.5px;
    right: 536px;
    bottom: 99px;
    font-size: 15pt;
    font-weight: bold;
    font-family: 'Roboto Mono', monospace;
  }

  .F17 {
    left: 171.7px;
    top: 451.5px;
    right: 520px;
    bottom: 99px;
    font-size: 15pt;
    font-weight: bold;
    font-family: 'Roboto Mono', monospace;
  }

  .F18 {
    left: 208.7px;
    top: 451.5px;
    right: 530px;
    bottom: 99px;
    font-size: 15pt;
    font-weight: bold;
    font-family: 'Roboto Mono', monospace;
  }

  .F19 {
    left: 452px;
    top: 450.5px;
    right: 50px;
    bottom: 99px;
    font-size: 13pt;
    font-family: 'Roboto Mono', monospace;
  }

  .F20 {
    left: 611px;
    top: 494px;
    right: 200px;
    bottom: 99px;
    font-size: 13pt;
    font-family: 'Roboto Mono', monospace;
  }

  .F21 {
    left: 258.5px;
    top: 494px;
    right: 532.8px;
    bottom: 99px;
    font-size: 13pt;
    font-family: 'Roboto Mono', monospace;
  }

  .F22 {
    left: 326.7px;
    top: 439.5px;
    right: 163.4px;
    bottom: 524.4px;
    width: 38px;
    height: 57px;
    font-family: 'Roboto Mono', monospace;
  }

  // .F23 {
  //   left: 416.2px;
  //   top: 597px;
  //   right: 50px;
  //   bottom: 90px;
  //   font-family: 'Roboto Mono', monospace;
  // }

  .F24 {
    left: 412.7px;
    top: 628.5px;
    right: 200px;
    bottom: 90px;
    font-size: 13pt;
    font-family: 'Roboto Mono', monospace;
  }

  .F25 {
    left: 412.7px;
    top: 653.5px;
    right: 450px;
    bottom: 90px;
    font-size: 13pt;
    font-family: 'Roboto Mono', monospace;
  }

  // .F26 {
  //   left: 197.2px;
  //   top: 431px;
  //   right: 536px;
  //   bottom: 99px;
  //   font-size: 13pt;
  //   font-family: 'Roboto Mono', monospace;
  // }

  .F27 {
    left: 770px;
    top: 17.5px;
    // top: 100px;
    right: 30px;
    bottom: 38.8px;
    @extend .Text5;
    text-align: left;
  }

  .F28 {
    left: 779px;
    top: 32.2px;
    right: 40px;
    bottom: 53.2px;
    @extend .Text5;
    text-align: left;
  }

  .F29 {
    left: 749px;
    top: 46.5px;
    right: 0px;
    bottom: 64.6px;
    @extend .Text5;
    text-align: left;
  }

  .F30 {
    left: 754px;
    top: 62.5px;
    right: 05px;
    bottom: 78px;
    @extend .Text5;
    text-align: left;
  }

  .F31 {
    left: 754px;
    top: 68.5px;
    right: 5px;
    bottom: 78px;
    @extend .Text5;
    text-align: left;
  }

  .F32 {
    left: 776.5px;
    top: 136.9px;
    right: 5px;
    bottom: 140.6px;
    @extend .Text5;
    text-align: left;
  }

  .F33 {
    left: 766px;
    top: 152.5px;
    right: 35px;
    bottom: 152px;
    @extend .Text5;
    text-align: left;
  }

  .F34 {
    left: 840px;
    top: 168px;
    right: 0px;
    bottom: 160.4px;
    @extend .Text5;
    text-align: left;
  }

  .F35 {
    left: 845px;
    top: 184.5px;
    right: 0px;
    bottom: 172.5px;
    @extend .Text5;
    text-align: left;
  }

  .F36 {
    left: 778px;
    top: 200px;
    right: 30px;
    bottom: 188.5px;
    @extend .Text5;
    text-align: left;
  }

  .F37 {
    left: 783px;
    top: 326.5px;
    right: 25px;
    bottom: 289px;
    @extend .Text5;
    text-align: left;
  }

  .F38 {
    left: 880.1px;
    top: 342.5px;
    right: 05px;
    bottom: 296.4px;
    @extend .Text5;
    text-align: left;
  }

  .F39 {
    left: 808.5px;
    top: 380px;
    right: 10px;
    bottom: 328.7px;
    @extend .Text5;
    text-align: left;
  }

  .F40 {
    left: 838px;
    top: 392.5px;
    right: 50px;
    bottom: 338.2px;
    @extend .Text5;
    text-align: left;
  }

  .F41 {
    left: 718.6px;
    top: 414px;
    right: 35px;
    bottom: 355px;
    @extend .Text5;
    text-align: right;
  }

  .F42 {
    left: 718.6px;
    top: 430.5px;
    right: 35px;
    bottom: 366.3px;
    @extend .Text5;
    text-align: right;
  }

  .F43 {
    left: 718.6px;
    top: 448.1px;
    right: 35px;
    bottom: 376.2px;
    @extend .Text5;
    text-align: right;
  }

  .F44 {
    left: 718.6px;
    top: 465px;
    right: 35px;
    bottom: 40px;
    @extend .Text5;
    text-align: right;
  }

  .F45 {
    left: 718.6px;
    top: 484.1px;
    right: 35px;
    bottom: 300px;
    @extend .Text5;
    text-align: right;
  }

  .F46 {
    left: 736.6px;
    top: 512.5px;
    right: 35px;
    bottom: 440.8px;
    font-size: 10pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: right;

  }

  .F47 {
    left: 945px;
    top: 608px;
    right: 0px;
    bottom: 300px;
    @extend .Text5;
    text-align: left;
  }

  .dashed-line {
    border: 1px dashed #000000;
  }
}

@page {
  size: A4;
  margin: 0;
}


@media all {

  .web-print-card,
  .arrears-card {
    position: relative;

  }
}

@media print {

  .web-print-card {
    width: 1015px;
    height: 1150px;
    //page-break-after: always;
  }

  .weblicence-print .web-print-card,
  .print-card .web-print-card {
    page-break-after: always;
  }

  .arrears-card {
    width: 1000px;
    height: 1129px;
    //page-break-after: always;
  }

  span {
    position: absolute;
  }


  .Text5 {
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
  }

  .F27 {
    left: 770px;
    top: 17.5px;
    // top: 100px;
    right: 30px;
    bottom: 38.8px;
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: left;
  }

  .F28 {
    left: 779px;
    top: 32.2px;
    right: 40px;
    bottom: 53.2px;
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: left;
  }

  .F29 {
    left: 749px;
    top: 46.5px;
    right: 0px;
    bottom: 64.6px;
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: left;
  }

  .F30 {
    left: 754px;
    top: 62.5px;
    right: 05px;
    bottom: 78px;
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: left;
  }

  .F31 {
    left: 754px;
    top: 68.5px;
    right: 5px;
    bottom: 78px;
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: left;
  }

  .F32 {
    left: 776.5px;
    top: 136.9px;
    right: 5px;
    bottom: 140.6px;
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: left;
  }

  .F33 {
    left: 766px;
    top: 152.5px;
    right: 35px;
    bottom: 152px;
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: left;
  }

  .F34 {
    left: 840px;
    top: 168px;
    right: 0px;
    bottom: 160.4px;
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: left;
  }

  .F35 {
    left: 845px;
    top: 184.5px;
    right: 0px;
    bottom: 172.5px;
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: left;
  }

  .F36 {
    left: 778px;
    top: 200px;
    right: 30px;
    bottom: 188.5px;
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: left;
  }

  .F37 {
    left: 783px;
    top: 326.5px;
    right: 25px;
    bottom: 289px;
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: left;
  }

  .F38 {
    left: 880.1px;
    top: 342.5px;
    right: 05px;
    bottom: 296.4px;
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: left;
  }

  .F39 {
    left: 808.5px;
    top: 380px;
    right: 10px;
    bottom: 328.7px;
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: left;
  }

  .F40 {
    left: 838px;
    top: 392.5px;
    right: 50px;
    bottom: 338.2px;
    font-size: 9pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: left;
  }

  .F41 {
    left: 718.6px;
    top: 414px;
    right: 35px;
    bottom: 355px;
    // @extend .Text5;
    text-align: right;
  }

  .F42 {
    left: 718.6px;
    top: 430.5px;
    right: 35px;
    bottom: 366.3px;
    // @extend .Text5;
    text-align: right;
  }

  .F43 {
    left: 718.6px;
    top: 448.1px;
    right: 35px;
    bottom: 376.2px;
    // @extend .Text5;
    text-align: right;
  }

  .F44 {
    left: 718.6px;
    top: 465px;
    right: 35px;
    bottom: 40px;
    // @extend .Text5;
    text-align: right;
  }

  .F45 {
    left: 718.6px;
    top: 484.1px;
    right: 35px;
    bottom: 300px;
    // @extend .Text5;
    text-align: right;
  }

  .F46 {
    left: 736.6px;
    top: 512.5px;
    right: 35px;
    bottom: 440.8px;
    font-size: 10pt;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    text-align: right;

  }

  .F47 {
    left: 945px;
    top: 608px;
    right: 0px;
    bottom: 300px;
    // @extend .Text5;
    text-align: left;
  }

  .dashed-line {
    border: 1px dashed #000000;
  }

}


.arrears-card {
  min-width: 1000px;
  height: 900px;
  margin-top: 5px;
  margin-right: 0px;
  font-family: sans-serif;
  font-size: 16px;

  span {
    position: absolute;
  }

  .arrear-province1 {
    bottom: 0pt;
    right: 0pt;
    top: 95pt;
    left: 60pt;
    font-size: 16px;
  }

  .arrear-province2 {
    bottom: 0pt;
    right: 0pt;
    top: 100pt;
    left: 60pt;
    font-size: 16px;
  }

  .arrear-province3 {
    bottom: 0pt;
    right: 0pt;
    top: 105pt;
    left: 60pt;
    font-size: 16px;
  }

  .receipt-type1 {
    bottom: 0pt;
    right: 0pt;
    top: 95pt;
    left: 85pt;
    font-size: 16px;
  }

  .receipt-type2 {
    bottom: 0pt;
    right: 0pt;
    top: 100pt;
    left: 85pt;
    font-size: 16px;
  }

  .receipt-type3 {
    bottom: 0pt;
    right: 0pt;
    top: 105pt;
    left: 85pt;
    font-size: 16px;
  }

  .arrear-variable1 {
    bottom: 0pt;
    right: 0pt;
    top: 100pt;
    left: 125pt;
    text-decoration: underline;
    font-size: 21px;
    font-weight: bold;
  }

  .arrear-variable2 {
    bottom: 0pt;
    right: 0pt;
    top: 107pt;
    left: 126pt;
    font-size: 21px;
    font-weight: bold;
  }

  .arrear-variable3 {
    bottom: 0pt;
    right: 0pt;
    top: 102.5pt;
    left: 132pt;
    font-size: 21px;
    font-weight: bold;
  }

  .serial-no {
    bottom: 0pt;
    right: 0pt;
    top: 100pt;
    left: 160pt;
    font-size: 21px;
    font-weight: bold;
  }

  .label1 {
    bottom: 0pt;
    right: 0pt;
    top: 125pt;
    left: 195pt;
    font-size: 16px;
  }

  .label2 {
    bottom: 0pt;
    right: 0pt;
    top: 125pt;
    left: 310.5pt;
    font-size: 16px;
  }

  .label3 {
    bottom: 0pt;
    right: 0pt;
    top: 145pt;
    left: 95pt;
    font-family: "nirmala ui";
    font-weight: bold;
    font-size: 25px;

  }

  .label4 {
    bottom: 0pt;
    right: 0pt;
    top: 165pt;
    left: 75pt;
    font-family: bold;
    font-size: 21px;
  }

  .label5 {
    bottom: 0pt;
    right: 0pt;
    top: 185pt;
    left: 177pt;
    font-family: "nirmala ui";
    font-weight: bold;
    font-size: 25px;
  }

  .label6 {
    bottom: 0pt;
    right: 0pt;
    top: 210pt;
    left: 60pt;
    font-size: 16px;
  }

  .label7 {
    bottom: 0pt;
    right: 0pt;
    top: 223pt;
    left: 60pt;
    font-size: 16px;
  }

  .label8 {
    bottom: 0pt;
    right: 0pt;
    top: 233pt;
    left: 60pt;
    font-size: 16px;
  }

  .label9 {
    bottom: 0pt;
    right: 0pt;
    top: 243pt;
    left: 60pt;
    font-size: 16px;
  }

  .label10 {
    bottom: 0pt;
    right: 15pt;
    top: 223pt;
    left: 273pt;
    font-size: 16px;
  }

  .label11 {
    bottom: 0pt;
    right: 15pt;
    top: 238pt;
    left: 273pt;
    font-size: 16px;
  }

  .label12 {
    bottom: 0pt;
    right: 0pt;
    top: 210pt;
    left: 235pt;
    font-size: 44pt;
  }

  .label13 {
    bottom: 0pt;
    right: 0pt;
    top: 285pt;
    left: 60pt;
    font-size: 16px;
  }

  .label14 {
    bottom: 0pt;
    right: 0pt;
    top: 285pt;
    left: 273pt;
    font-size: 16px;
  }

  .label15 {
    bottom: 0pt;
    right: 0pt;
    top: 312pt;
    left: 273pt;
    font-size: 16px;
  }

  .label16 {
    bottom: 0pt;
    right: 0pt;
    top: 275pt;
    left: 60pt;
    font-size: 16px;
  }

  .label17 {
    bottom: 0pt;
    right: 0pt;
    top: 245pt;
    left: 125pt;

  }

  .label18 {
    bottom: 0pt;
    right: 0pt;
    top: 245pt;
    left: 155pt;

  }

  .label19 {
    bottom: 0pt;
    right: 0pt;
    top: 297pt;
    left: 60pt;

  }

  .label20 {
    bottom: 0pt;
    right: 0pt;
    top: 312pt;
    left: 60pt;

  }

  .label21 {
    bottom: 0pt;
    right: 0pt;
    top: 285pt;
    left: 235pt;
    font-size: 36pt;
  }

  .label22 {
    bottom: 0pt;
    right: 0pt;
    top: 355pt;
    left: 60pt;

  }

  .label23 {
    bottom: 0pt;
    right: 0pt;
    top: 355pt;
    left: 235pt;
  }

  .label24 {
    bottom: 0pt;
    right: 0pt;
    top: 375pt;
    left: 60pt;
  }

  .label25 {
    bottom: 0pt;
    right: 0pt;
    top: 315pt;
    left: 60pt;
  }

  .label26 {
    bottom: 0pt;
    right: 0pt;
    top: 425pt;
    left: 60pt;
  }

  .label27 {
    bottom: 0pt;
    right: 0pt;
    top: 425pt;
    left: 140pt;
  }

  .label28 {
    bottom: 0pt;
    right: 0pt;
    top: 485pt;
    left: 205pt;
  }

  .label29 {
    bottom: 0pt;
    right: 0pt;
    top: 500pt;
    left: 205pt;
  }

  .label30 {
    bottom: 0pt;
    right: 0pt;
    top: 515pt;
    left: 205pt;
  }
}