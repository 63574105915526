/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-10-13 08:35:49 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-10-20 11:22:33
 */


 .webLicenceTabWrapper{
     .defaultAppBarWrapper{

        .defaultTabItemWrapper{
            span{
                color: coral !important;
            }
        }

        .MuiTabs-indicator {
            background-color:  coral !important;
        }
        
     }
 }