/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-29 11:54:36 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-10-22 09:51:36
 */


 .cashierWrapper{
     
    .serialNumberWrapper{

        .defaultInputWrapper{
            @include calc(width, "100% - 26px");
        }

        .defaultInputBtnStyle{
            margin-top: 16px;
            padding: 4px 6px !important;
        } 
        
    }
 }

 .cashier-history-label-wrapper{
    padding-top:0.5rem;
 }

 .label-conatiner-center-wrapper{
     display: flex;
     gap:8px;
     padding-left: 0px;
     padding-right: 0px;
     .label-right-align{
         text-align: right;
         padding-left: 0px;
         padding-right: 0px;
     }
     .value-left-align{
        text-align: left;
        padding-left: 0px;
        padding-right: 0px;
     }
 }

 .loadSerialNumber{
    z-index: 2300 !important;
 }