/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-19 17:50:39 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-07 19:05:52
 */
 
.subMenuWrapper{
 
  .defaultListItemWrapper{
    padding: 5px 0px 5px 5px;
    
    .MuiListItemIcon-root{
      min-width: 20px !important ;
    }

    .defaultListItemIcon{
      color: $menuFontColor !important;
      font-size: 14px !important;  
      width:30px !important;    
    }

    .leftIcon{
      padding-right: 10px;
    }

    .defaultListItemText{    
      visibility: hidden;  
      span{
        color: $menuFontColor !important;
        font-size: 14px !important;
        &:hover{
          text-decoration:none !important;
        }
      }
    }  
    
    &:hover{
      .defaultListItemIcon{
        color: $secondaryColor !important;
      }
      .defaultListItemText{
        span{
          color: $secondaryColor !important;
        }
      }
    }
   
  }
  
}

.menuActive {
  background-color: rgb(78, 78, 99) !important;

  &:hover{
    .defaultListItemIcon{
      color: $menuFontColor !important;
    }
    .defaultListItemText{
      span{
        color: $menuFontColor !important;
      }
    }
  }
}
.templateTwoSideMenuHide{

  .subMenuWrapper{
    .defaultListItemText{
      visibility: hidden;
      transition: visibility .3s;        
    }
  }
  .subMenuWrapper{
    .defaultListItemText{
      display: none;
           
    }
    .secondMenuStyle {
      display: none;
    }
  }
  .subMenuWrapper .defaultListItemWrapper{
    margin-bottom: 10px;
  }
  
}

.templateTwoSideMenuShow{
  .subMenuWrapper{
    .defaultListItemText{
      visibility: visible;
      transition: visibility 1s;        
    }
  }
}

.subMenuWrapper.sideMenuLargePadding,.subMenuWrapper.sideMenuPadding {
  height: 100vh !important;
  overflow-y: auto;
  /* width */
/* width */

}

.subMenuWrapper.sideMenuLargePadding::-webkit-scrollbar,.subMenuWrapper.sideMenuPadding::-webkit-scrollbar {
  width: 10px;
  height: 13px;
}
.subMenuWrapper.sideMenuLargePadding::-webkit-scrollbar-thumb,.subMenuWrapper.sideMenuPadding::-webkit-scrollbar-thumb {
  background: #3b4c68;
  border-radius: 10px;
}
.subMenuWrapper.sideMenuLargePadding::-webkit-scrollbar-thumb:hover {
  // background: linear-gradient(13deg, #c7ceff 14%, #f9d4ff 64%);
}
.subMenuWrapper.sideMenuLargePadding::-webkit-scrollbar-track {
   background: #273244;
  border-radius: 10px;
  // box-shadow: inset 7px 10px 12px #f0f0f0;
}
