.amount-breakedown-card {
  table {
    width: 100%;
    tr {
      td {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: $defaultFontSize;
        div {
          .text-right {
            input {
              text-align: right !important;
            }
          }
        }
      }
    }
  }
}

.paymentType {
  div {
    .text-right{
      input {
        text-align: right !important;
      }
      input:placeholder-shown {   
        text-align: left !important;
      }
    }
  }
}

.serialNumberWrapper {
  .defaultInputWrapper {
    @include calc(width, "100% - 26px");
  }

  .defaultInputBtnStyle {
    margin-top: 16px;
    padding: 4px 6px !important;
  }

  .licenseSequenceInputBox{
    display: flex;
    flex-direction: row;
    
    button{
        border: none;
        background-color:#E0E0E0;
        margin-left: 0px;
        height: 24px;
        i{
            align-self: center;
            font-size: 16px;           
        }
    }

    input{
        border:1px solid #E0E0E0;
        padding: 0px 10px 0px 10px;
        width: 180px;
        font-size: $defaultFontSize !important;
        height: 24px;
    }

    label{
        border:1px solid #E0E0E0;
        padding: 4px 10px 0px 10px;
        width: 180px;
        font-size: $defaultFontSize !important;
        height: 24px;
        background-color: #fafafa;
    }

    .serialLabel{
        width: 120px;
        padding-top: 2px;
    }
  }
}
