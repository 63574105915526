

.gray-color{
    color: gray;
}
.yellow-color{
    color: yellow;
}

.red-color{
    color: red;
}
.quatationSearchWrapper{
    .MuiInputBase-root{
        margin-top: 0px !important;
    }
    .defaultInputWrapper {
        margin-bottom: 0px !important;
    }
    .quotationSearchLabel{
        width: 63px;
        padding-top: 3px;
    }
    .quotation-selectbox{
        width: 120px;
        margin-top: 0px;
    }
}
