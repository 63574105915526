/* Slideshow container */

.slideshow-container {
    max-width: 500px;
    position: relative;
    border: 1px solid blue;
    box-sizing:border-box;
    display: flex;
    width:  400px;
    img {
        width:  400px;
        height: 400px;
    }
    .prev, .next {
        position: absolute;
        top: 50%;
        left: 2%;
    }
    .next {
        left:94%;
    }    
    .helperText { 
      position: absolute;
      top: 100%;
      margin-left: 5%;
    }
}

.license-slide-container {
    // max-width: 500px;
    position: relative;
    // border: 1px solid blue;
    box-sizing:border-box;
    display: flex;
    justify-content: center;
    width:  100%;
    .licence-preview-card {
        margin-right: 70px;
        z-index: 0;
    }
    .prev, .next {
        position: absolute;
        top: 50%;
        left: -5%;
        z-index: 2;
    }
    .next {
        left:96%;
    }    
    .helperText { 
      position: absolute;
      top: 100%;
      margin-left: 5%;
    }
}
.license-slide-container-dealer{
    .prev {
        left: -5%;
        z-index: 2;
    }
    .next {
        left:94%;
    } 
}
.license-slide-container-web {
    // max-width: 500px;
    position: relative;
    // border: 1px solid blue;
    box-sizing:border-box;
    display: flex;
    justify-content: center;
    min-width: 550px;
    width:  100%;
    .licence-preview-card {
        margin-left:50px;
        z-index: 0;
    }
    .prev, .next {
        position: absolute;
        top: 50%;
        left: 2%;
        z-index: 2;
    }
    .next {
        left:94%;
    }    
    .helperText { 
      position: absolute;
      top: 100%;
      margin-left: 5%;
    }
}

.envelop-slide-container-web{
    // max-width: 500px;
    position: relative;
    // border: 1px solid blue;
    box-sizing:border-box;
    display: flex;
    justify-content: center;
    min-width: 550px;
    width:  100%;
    .licence-preview-card {
        // margin-left:50px;
        z-index: 0;
    }
    .prev, .next {
        position: absolute;
        top: 50%;
        left: -4%;
        z-index: 2;
    }
    .next {
        left:99%;
    }    
    .helperText { 
      position: absolute;
      top: 100%;
      margin-left: 5%;
    }
}


.dealerLicenseImage{
    
}




