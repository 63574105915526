/*
 * @Author: Chanaka Wickramasinghe
 * @Description: ExpansionCard styles
 * @Date: 2021-07-08 16:57:21
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-07-08 16:57:21
 */

.hidePaddingClass {
  .cardBodyWrapper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}