/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-10-13 08:35:49 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-10-20 11:22:33
 */


.webLicenceTabWrapper {
    .defaultAppBarWrapper {

        .defaultTabItemWrapper {
            span {
                color: coral !important;
            }
        }

        .MuiTabs-indicator {
            background-color: coral !important;
        }

    }
}

.envelope-visibility,.weblicence-visibility {
    display: none;
}

.envelope-print-card {
    width: 304px;
    height: 149px;
    margin: 0px;

    span {
        position: absolute;
        font-family: sans-serif;
    }

    .tracking_id {
        bottom: 35px;
        right: 100px;
        top: 63px;
        left:109.5px;
        font-weight: bold;
        font-size: 23px;
    }

    .revenue_licence_number {
        bottom: 30px;
        right: 50px;
        top: 93.5px;
        left:109.5px;
        font-weight: bold;
        font-size: 23px;
    }

    .from {
        bottom: 0px;
        right: 95px;
        top: 264px;
        left: 109.5px;
        font-size: 18px;
        font-weight: bold;
    }

    .province_name1 {
        bottom: 35px;
        right: 80px;
        top: 266px;
        left: 174.5px;
        font-size: 17px;
    }

    .province_name2 {
        bottom: 26pt;
        right: 98pt;
        top: 287px;
        left: 174.5px;
        font-size: 17px;
    }

    .provice_address1 {
        bottom: 5px;
        right: 131px;
        top: 312px;
        left: 174.5px;
        font-size: 17px;
    }

    .provice_address2 {
        bottom: 05px;
        right: 131px;
        top: 331px;
        left: 174.5px;
        font-size: 17px;

    }

    .provice_address3 {
        bottom: 5px;
        right: 131px;
        top: 341px;
        left: 174.5px;
        font-size: 17px;

    }

    .phone {
        bottom: 3px;
        right: 20px;
        top: 352px;
        left: 174.5px;
        font-size: 17px;
    }

    .web_address {
        bottom: 5px;
        right: 95px;
        top: 369px;
        left: 174.5px;
        font-size: 17px;
    }

    .to {
        bottom: 35px;
        right: 0px;
        top: 264px;
        left: 594.5px;
        font-size: 19px;
        font-weight: bold;
    }

    .owner_name1 {
        bottom: 5px;
        right: 0px;
        top: 266px;
        left: 639.5px;
        font-size: 17px;

    }

    .owner_name2 {
        bottom: 35px;
        right: 0px;
        top: 287px;
        left: 639.5px;
        font-size: 17px;

    }

    .owner_name3 {
        bottom: 35px;
        right: 0px;
        top: 297px;
        left: 639.5px;
        font-size: 17px;
    }

    .owner_address1 {
        bottom: 35px;
        right: 0px;
        top: 318px;
        left: 639.5px;
        font-size: 17px;
    }

    .owner_address2 {
        bottom: 5px;
        right: 0px;
        top: 336px;
        left: 639.5px;
        font-size: 17px;
    }

    .owner_address3 {
        bottom: 35px;
        right: 0px;
        top: 349px;
        left:639.5px;
        font-size: 17px;
    }
}

@page {
    size: A4 portrait;
}

@media all {
    .envelope-print-card {
        position: relative;
       
    }



}

@media print {
    .envelope-print-card {
         width: 830px;
         height: 149px;
        margin: 0px;
        page-break-before: always;
    }

}