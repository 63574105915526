/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-19 17:47:56 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-23 16:31:48
 */

 .headerAppBarMainWrapper{
    box-shadow: 0 0 10px 1px rgba(68,102,242,.05);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $appBarBGColor;
   height: $appbarHeight;
   box-shadow: 0 0 10px 1px rgba(68,102,242,.05);
    
    .appBarLeftWrapper{
        height: $appbarHeight;
        @include flexDirection(row);
         .logoWrapper{
             @extend .floatLeft;
             width: $menuBarWidth;            
             height: $appbarHeight;
             text-align: center;
                         
             .logo{                
                 margin: auto;
                 height:  $appbarHeight;
                 width: auto;
             }
         }
         .hamburgerIconWrapper{
             @extend .floatLeft;
             @include displayFlex;
             @include justifyContent(center);
             height: $appbarHeight;
 
             .iconStyle{
                 @include alignSelf(center);
                 font-size: 30px;
                 padding: 0px $defaultPadding 0px $defaultPadding;
                 color: $primaryColor;
                 cursor: pointer;
 
                 &:hover{
                     font-size: 32px;
                     font-weight: bold;
                 }
             }
         }
    }
 
    .appBarRightWrapper{
         height: $appbarHeight;
         @include displayFlex;
         @include flexDirection(row);
         @include justifyContent(flex-end);
 
         .iconItem{ 
             @include alignSelf(center);
             padding: 2px 20px 2px 20px;
             
             .iconStyle{
                 font-size: 18px;
                 color: $primaryColor;
             }
 
             border-left: 1px solid $appBarIconSeparatorColor;
         }
 
         .notification{
           border-right: 1px solid $appBarIconSeparatorColor;
         }
 
         .message{
             border-right: 1px solid $appBarIconSeparatorColor;
         }
 
         .profileImg{
             @include alignSelf(center);
             padding: 0px 20px 0px 20px;
             .img{
                 border-radius: 50px;
                 width: 50px;
                 height: 50px;
             }
         }

        
    }

 }


 .templateOneWrapper{

    
        
        //desktop
        @media screen and (min-width: $mobileBreakPoint) { 
            .headerAppBarMainWrapper{  
                z-index:20 !important; 
                .logoWrapper{
                    display: none !important;
                }
            }
            .halfHeaderAppBarWrapper{
                padding-left: $menuBarWidth;
                transition:padding-left 1s;  
            }
            .fullHeaderAppBarWrapper{
                padding-left: 0px;
                transition:padding-left 1s;  
            }
        }
        //mobile
        @media screen and (max-width: $mobileBreakPoint) {
            .headerAppBarMainWrapper {    
                z-index:35 !important;      
                .logoWrapper{
                    display: block !important;
                }
            }
            .halfHeaderAppBarWrapper{
                padding-left: 0px;
                transition:padding-left 1s;  
            }
            .fullHeaderAppBarWrapper{
                padding-left: 0px;
                transition:padding-left 1s;  
            }
        }

        @media screen and (max-width: $smallDevice) {
            .headerAppBarMainWrapper {         
                .appBarLeftWrapper{
                    width: $menuBarWidth;   
                    .logoWrapper{
                        width: 50%;
                    }  
                    .hamburgerIconWrapper{
                        width: 50%;
                    }
                }
                .appBarRightWrapper{
                    @include calc(width, "100% - #{$menuBarWidth}");
                }
            }
        }

        @media screen and (max-width: $extraSmallDevice2) {
            .headerAppBarMainWrapper {   
                .appBarLeftWrapper{  
                    width :50%; 
                    .logoWrapper{
                        float: left;
                        .logo{
                            height: auto;
                            width: 120px;
                            padding-top: 10px;
                        }
                    }                   
                }
                .appBarRightWrapper{
                    width :50%;
                }
            }
        }

 }

.hedaerMenus{
   
    .MuiPaper-root{
        top: 55px !important;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
        border: 1px solid $separatorColor !important;

        .defaultMenuItem{
            p{
                font-size: $defaultFontSize;
                .defaultIconStyle{
                    margin-right: 10px !important;
                    font-size: 16px;
                }

                &:hover{
                    color: $primaryColor !important;
                }
            }
        }

        .profileMenuItem{
            min-width: 150px;
        }
    }

}
