
    .scrollBar{
        //background-color: lightblue;
        width: 1000px;
        overflow-x: scroll;
        text-align:center
    };

    


    .tableTitle{

        text-align:center
    };

    .dateWidth{

        width: 200px;

    }

    .report-heading{
        padding-top: 8px;
        padding-bottom: 15px;
        h5{
            color: #111;
            font-size: 16px;
            font-weight: 500;
        }
    }
    
    .report-btn-wrapper{
        padding-top: 16px;
        padding-right: 32px;
        justify-content: center;
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
    
    .report-html-view{
        max-height: 720px;
        overflow: auto;
    }
    .report-html-view .false{
        overflow-x: scroll;
        //text-align:center
    };
    .reportExportToAlignWrapper{
        display: flex;
        align-items: center;
        justify-content: right;
        float: right;
    
        .exporTypeSelectDropdown{
            min-width: 220px;
            float:right;
            padding-left:16px;
        }
    
        .exportBtn{
            padding-top: 8px;
            float:right;
            padding-left:16px;
        }
    }
 
