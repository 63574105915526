/**
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-02-20 10:18:12
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-10-02 12:11:43
 */

 .initclass{
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    border: none;
    font-weight: normal;
    text-shadow:none;  
    background-color: none;
    box-shadow:none;
}

.defaultFontFamily{
    font-family: 'Mada',sans-serif !important;
 }
 
.defaultFontSize{
    font-size: $defaultFontSize !important;
}
 
 body{
     @extend .initclass;
     @extend .defaultFontFamily;
     @extend .defaultFontSize;
 }
 
 body,h1,h2,h3,h4,h5,h6,p,font,span,div,a,small,table{
     @extend .initclass;
     @extend .defaultFontFamily;
 }

 a{
     text-decoration: none !important;
 }

.floatLeft {
    float: left;
}

.floatRight {
   float: right;
}

.floatNone {
   float: none !important;
   clear: both !important;
}

.displayNone {
    display: none !important;
}

.displayBlock {
    display: block !important;
}

.clearBoth{
    clear: both;
}

.fullWidthDiv {
    @extend .floatLeft;
    width: 100%;
}

.halfWidthDiv {
    @extend .floatLeft;
    width: 50%;
}

.paddingNone {
    padding: 0px !important;
}

.paddingTopBottomNone {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.paddingLeftRightNone {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.paddingBottomNone {
    padding-bottom: 0px !important;
}

.paddingTopNone{
    padding-top: 0px !important;
}

.paddingLeftNone {
    padding-left: 0px !important;
}

.paddingRightNone {
    padding-right: 0px !important;
}

.defaultPadding {
    padding: $defaultPadding;
}

.defaultHalfPadding {
    padding: $defaultHalfPadding !important;
}

.defaultPaddingTopBottom {
    padding-top: $defaultPadding;
    padding-bottom: $defaultPadding;
}

.defaultHalfPaddingTopBottom {
    padding-top: $defaultHalfPadding !important;
    padding-bottom: $defaultHalfPadding !important;
}

.defaultPaddingLeftRight {
    padding-left: $defaultPadding;
    padding-right: $defaultPadding;
}

.defaultHalfPaddingLeftRight {
    padding-left: $defaultHalfPadding !important;
    padding-right: $defaultHalfPadding !important;
}

.defaultPaddingBottom {
    padding-bottom: $defaultPadding;
}

.defaultHalfPaddingBottom {
    padding-bottom: $defaultHalfPadding !important;
}

.defaultPaddingTop {
    padding-top: $defaultPadding;
}

.defaultHalfPaddingTop {
    padding-top: $defaultHalfPadding !important;
}

.defaultPaddingLeft {
    padding-left: $defaultPadding;
}

.defaultHalfPaddingLeft {
    padding-left: $defaultHalfPadding !important;
}

.defaultPaddingRight {
    padding-right: $defaultPadding;
}

.defaultHalfPaddingRight {
    padding-right: $defaultHalfPadding !important;
}

.marginNone {
    margin: 0px !important;
}

.marginTopBottomNone {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.marginLeftRightNone {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.marginBottomNone {
    margin-bottom: 0px !important;
}

.marginTopNone{
    margin-top: 0px !important;
}

.marginLeftNone {
    margin-left: 0px !important;
}

.marginRightNone {
    margin-right: 0px !important;
}

.defaultMargin {
    margin: $defaultMargin;
}

.defaultHalfMargin {
    margin: $defaultHalfMargin !important;
}

.defaultMarginTopBottom {
    margin-top: $defaultMargin;
    margin-bottom: $defaultMargin;
}

.defaultHalfMarginTopBottom {
    margin-top: $defaultHalfMargin !important;
    margin-bottom: $defaultHalfMargin !important;
}

.defaultMarginLeftRight {
    margin-left: $defaultMargin;
    margin-right: $defaultMargin;
}

.defaultHalfMarginLeftRight {
    margin-left: $defaultHalfMargin !important;
    margin-right: $defaultHalfMargin !important;
}

.defaultMarginBottom {
    margin-bottom: $defaultMargin;
}

.defaultHalfMarginBottom {
    margin-bottom: $defaultHalfMargin !important;
}

.defaultMarginTop {
    margin-top: $defaultMargin;
}

.defaultHalfMarginTop {
    margin-top: $defaultHalfMargin !important;
}

.defaultMarginLeft {
    margin-left: $defaultMargin;
}

.defaultHalfMarginLeft {
    margin-left: $defaultHalfMargin !important;
}

.defaultMarginRight {
    margin-right: $defaultMargin;
}

.defaultHalfMarginRight {
    margin-right: $defaultHalfMargin !important;
}

.defaultPageTitle {
    font-size: $defaultTitle;
}

.setElementToCenter {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


//*****
.checkListResultsCriteriaBasedTable{
    .setbackgroundTR{
        background-color: #ffdac2 !important;
    }
}
//***********


//heading

.defaultHeading{
    color: #111;
                font-size: 16px;
                font-weight: 500; 
}
.initialLoader {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $bodyColor;
    width: 100%;
    position: fixed;
    z-index: 99999999999999999;
  
    .loader {
      position: absolute;
      left: 50%;
      top: 45%;
      color: $primaryColor !important;
    }
  }