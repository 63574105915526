/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Slideshow css
 * @Date: 2021-08-05 12:14:12
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-08-05 12:14:12
 */

.prev-next-btn-style {
  background: none !important;
  border: none !important;
}

.caption-style {
  font-weight: 500;
}

.prev-next-btn-style:hover {
  background-color: rgba(0,0,0,0.8) !important;
}