/**
 * @Author: Chanaka Wickramasinghe
 * @Description: SelectBox styles
 * @Date: 2020-02-26 15:16:49
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-02-26 15:16:49
 */

// Material SelectBox css class
 .materialSelectBoxWrapper {

    .materialSelectWrapper {
        font-size: $defaultFontSize !important;
    }

    select {
        padding-top: 6px;
    }
 }

 .bootstrapSelectBoxWrapper {

    .bootstrapSelectWrapper {

        border: 1px solid $geyserColor;
        border-radius: .25rem;
        font-size: $defaultFontSize !important;
        font-weight: 400;
        padding: 5px 5px !important;
        height: auto !important;

        &:before {
            border-bottom: none !important;
        }

        &:after {
            border-bottom: none !important;
        }

        select {
            padding: 0px !important;
            margin-top: 1px !important;
        }
    }
    
 }
 .defaultSelectBoxWrapper {

    .defaultSelectWrapper {

        border: 1px solid red;
        border-radius: .25rem;
        font-size: $defaultFontSize !important;
        font-weight: 400;
        padding: 5px 5px !important;
        height: auto !important;

        &:before {
            border-bottom: none !important;
        }

        &:after {
            border-bottom: none !important;
        }

        select {
            padding: 0px !important;
            margin-top: 1px !important;
        }
    }
    
 }

 

// Bootstrap SelectBox css class
//  .defaultBootstrapSelectBoxWrapper {

//     label {
//         font-size: 11px !important;
//         font-weight: 400;
//         margin-top: 0px;
//         margin-bottom: 1px !important;
//         color: $nobel;
//     }

//     span {
//         color: $red;
//         margin-left: 2px;
//     }

//     select {
//         font-size: 13px !important;
//         font-weight: 400;
//         padding: 5px 5px;
//         height: auto !important;
//     }

//     .form-control:focus {
//         box-shadow: none !important;
//     }

//     small {
//         font-size: 11px !important;
//         margin-top: 5.45px !important;
//         line-height: 1em;
//         font-weight: 400;
//         min-height: 11px;
//     }
//  }

