.update-vehicle-text-box-label-wraapper{
    height: 49.25px;
}

.update-vehicle-date-picker-label-wraapper{
    height: 50.25px;
}

.update-vehicle-dropdwon-label-wraapper{
    height: 51.25px;
}

.update-vehicle-label{
    display: flex;
    align-items: center;
    padding-top: 5px;
}