/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-08 18:17:17 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-09-27 00:43:06
 */

 .counterTab{

    .defaultTabItemBodyWrapper{
      margin-bottom: 200px;
    }

 }



 .normalLicenseWrapper {
    @extend .fullWidthDiv;

    .topWrapper{
        @extend .fullWidthDiv;
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;
        border-bottom: 1px solid $separatorColor;

        .searchWrapper{
            display: flex;
            flex: 1; 
            flex-direction: row;
            
            .searchInputWrapper{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                width: 450px;
                label{
                    margin-right: 10px;
                    padding-top: 2px;
                }
                input{
                    width: 200px;
                    padding: 0px 5px 0px 5px !important;
                    font-size: $defaultFontSize;
                    max-height: 24px;

                    &:focus{
                        box-shadow: none;
                        border-color: #ced4da;
                    }
                }

                .searchInputBoxWrapper{
                    @extend input;
                    padding: 0 !important;
                    margin: 0 !important;
                }
                
                select{
                    width: 100px;
                    padding: 0px !important;
                    max-height: 24px;
                    margin-top: -2px;

                    &:focus{
                    }
                }

                .defaultInputBtnStyle{
                    padding:3px 5px 0px 5px !important;
                    margin-left: -2px;
                    .defaultInputBtnTxt{
                        font-size:16px !important;
                    }
                }
            }

            .quotationSearch{
                width: 450px !important;
            }
            
           
        }
        .statusWrapper{
            flex: 2;
            display: flex;
            justify-content: flex-end;
            .statusUL{
                list-style-type: none;
                margin: 0px;
                li{
                    display: inline; 
                    padding: 0px 15px 0px 15px;
                }
                .setBorder{
                    border-right: 1px solid $separatorColor;
                }
            }
        }
    }

    .normalLicenceWrapper{
        @extend .fullWidthDiv;
        padding-top: 10px;
        .documentValidation{
            
          .checkElement{
              .checkElementTxt{
                @include calc(width, "100% - 70px");
                float: left;  
              }

              .checkElementViewMore{
                  width: 70px;  
                  float: left;  
                  padding: 12px 0px 0px 10px !important;  
                  
                  .defaultInputBtnStyle{
                      padding: 2px 5px 2px 5px !important;
                      margin-top: 5px;
                  }
              }
            
          }
        }
    }

    .paymentHistoryTable{
        .dataTableBodyWrapper{
            overflow-y: scroll;
            height: 240px !important;
        }
        .pending-approval{
            background-color: #c1c1c1;
        }

        .reversed{
            background-color: #fdfd90;
        }

        .canceled{
            background-color: #ffa4a4;
        }
        // .odd_row{
        //     background: red;
        //  }
        
         .CAN{
            background-color: #ffa4a4;
         }
         .REV{
            background-color: #c1c1c1;
           
         }
         .PEN,.Appr{
            background-color: #fdfd90;
         }
        
        
    }
   

 }

 
 .weblicence{
    .dataTableBodyWrapper{
        overflow-y: scroll;
        height: 380px !important;
    }
}
.webreconcile{
    .dataTableBodyWrapper{
        .Void{
            background-color: rgb(240, 134, 134);
        }
        .Settled{
            background-color: rgb(138, 243, 171);
        }
    }
}
.scrollMessage{
    max-height: 200px;
    overflow-y: scroll;
}
 .specialMessage{
        .dataTableBodyWrapper{
            table{
    
                thead{
                    tr{
                        th{
                            padding: 5px 20px 5px 10px;
                           
                        }
                      
                    }
        }
    }}

    .BLUE{
        color: blue;
    }
    .RED{
        color: red;
    }
    

}
.specialMsg{
    z-index: 1000 !important;
}
 //pendinLicenceTable
 .pendinLicenceTable{
    .dataTableBodyWrapper{
        overflow-y: scroll;
        height: 240px;
    }
}
.highlight{
    background-color: rgb(142, 207, 233);
    padding: 5px;
}


 //commentHistoryWrapper
 .commentHistoryWrapper{
    @extend .fullWidthDiv;

    .commentElementWrapper{
        @extend .fullWidthDiv;
        border :1px solid $separatorColor;
        background-color: $white;
        padding: 10px;
        margin-bottom: 10px;
        h1{
            margin-bottom: 5px;
            font-size: 14px;
        }
        p{
           background-color: $bodyColor; 
           padding: 10px;
        }
    }
 }

 .requestFor{
    .MuiPaper-root {
       margin-top: 25px !important;
       ul{
           padding: 0px !important;
           li{
               font-size: 13px;
               min-width: 120px;
           }
       }
    }
}



.quotationWrapper{
    @extend .fullWidthDiv;
    margin-bottom: 100px !important;
    .vehicalListFromWrapper{
        @extend .fullWidthDiv;
       // margin-bottom: 100px !important;
    }
}


.addLicenceLabel{
    .mt{
        @extend .fullWidthDiv;
        margin-top: 1%;
    }   
    .mandatory{
        color: red;
    } 
}
.mandatory{
    color: red;
}