/*
 * @Author: Chanaka Wickramasinghe
 * @Description: RadioButton Styles
 * @Date: 2020-02-27 14:20:53
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-10-02 15:45:53
 */

// Material RadioButtonsGroup css class
.materialRadioBtnGrpWrapper {

    .radioBtnGrpLabel {
        font-size: $defaultLabel !important;
        color: $nobel !important;
        margin-bottom: 0px !important;

        span {
            color: $red;
        }
    }

    .errorTxt{
        color: $red  !important; 
    }

    .materialRadioGroupStyle {

        .defaultRadioLabel {

            margin-bottom: auto !important;

            span {
                font-size: $defaultFontSize !important;
                color: $darkGrey !important;
            }

            .defaultRadioButton {

                svg {
                    width: 17px !important;
                    height: 17px !important;
                }

            }
        }
    }

    .radioBtnGrpHelperText {
        margin-top: 4px !important;
    }
}

.bootstrapRadioBtnGrpWrapper{

    .radioBtnGrpLabel{
        margin-bottom: 0px !important;
    }

    .bootstrapRadioGroupStyle{
        .defaultRadioLabel{
            margin-bottom: 0px !important;

            .defaultRadioButton{
                padding: 5px !important;                
            }

            .MuiTypography-body1{
                font-size: 12px !important;
                color: $nobel !important;
            }
        }
    }

}