  .defaultSplitBtnStyle {

      max-height: 20px;
      min-width: 200px;



      .MuiButton-outlinedSizeSmall {
          padding: 3px 5px;
          font-size: 0.713rem;
      }

      .MuiButton-root {
          text-transform: capitalize;
      }

      .MuiButtonGroup-grouped {
          min-width: 16px;
      }

      .MuiSvgIcon-root {
          width: .7em;
          font-size: 1.5rem;
      }
  }



  // MenuList, MenuItem scss

  .menuListStyle {
      padding: 33px 35px;
      // padding-bottom: 5px;
      font-size: '0.7em';
  }
  .menuItemStyle {
      padding: 33px 35px;
      // padding-bottom: 5px;
      font-size: '0.7em';
  }