/*
 * @Author: Chanaka Wickramasinghe
 * @Description: DatePicker styles
 * @Date: 2020-03-03 18:05:36
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-10-14 09:52:30
 */

// Material DatePicker css class
.materialDatePickerStyle {

    margin: 0px 0px !important;
    //padding-bottom: 15px !important;
    
    label {
        @extend .defaultInputLabel;
    }

    input {
        font-size: $defaultFontSize !important;
    }

    span {
        color: inherit;
    }
}

.datePickerError {

    span {
        color: $red !important;
    }
}

// Bootstrap DatePicker css class
.bootstrapDatePickerStyle {

    margin: 0px 0px !important;
    padding-bottom: 0px !important;

    label {
        @extend .defaultInputLabel;
    }

    input {
        font-size: $defaultFontSize !important;
        padding-left: 7px !important;    
        padding-top:5px;
        padding-bottom: 5px;     
    }
}

// Bootstrap DatePicker 2nd div element css class
.bootstrapDatePickerStyle > div:nth-child(2) {
    border: 1px solid $geyserColor !important;
    border-radius: .25rem !important;

    &:before {
        border-bottom: none !important;
    }

    &:after {
        border-bottom: none !important;
    }
}

