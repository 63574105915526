.labelStyle {
  font-size: 12px !important;
  color: #949494;
  line-height: 0.9 !important;
  font-weight: 550;
  padding-bottom: 10px !important;
}

.checkBoxPadding {
  padding-left: 5px !important;
  padding-bottom: 5px !important;
}