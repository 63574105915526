/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Description: SCSS files initializer  
 * @Date: 2020-02-06 18:30:48 
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-08 10:43:43
 */

/* Include library files */
@import '../../node_modules//bootstrap/scss/bootstrap.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../node_modules/@mdi/font/scss/materialdesignicons.scss';


/* Include base files */
@import './base/variables';
@import './base/function';
@import './base/mixins';
@import './base/base';

/* Include form */
@import './layouts/ui-components/ui-elements/index';

/** Include template **/
@import './layouts/ui-components/templates/common/headerAppBar';
@import './layouts/ui-components/templates/common/menuBar';
@import './layouts/ui-components/templates/template-one/template-one';
@import './layouts/ui-components/templates/template-two/template-two';
@import './layouts/ui-components/templates/counter-template/counterTemplate';

/**Include Modules **/
@import './layouts/modules/index';


@import './layouts/modules/testUI/normalLicense';
@import './layouts/modules/testUI/webLicence';

